import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class EmpDirectoryService {
    constructor(public utl: UtilService) {
    }

    GetEmployeeDetails(inputData) {
        const options: any = {
            action: 'hrmemp/employee/getEmployees',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetEmployeDetailsForDirectory(inputData) {
        const options: any = {
            action: 'hrmemp/employee/getEmployeDetailsForDirectory',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetEmpAttendanceDetails(inputData) {
        const options: any = {
            action: 'staff/empattendance/getAttendanceDetailsForEmpDirectory',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetEmpAbsentDetail(inputData) {
        const options: any = {
            action: 'staff/empattendance/getEmpAbsentDetailForDateCriteria',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetParentComments(inputData) {
        const options: any = {
            action: 'staff/parentcomment/getParentCommentsForEmpDirectory',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetEmpTimetable(inputData) {
        const options: any = {
            action: 'staff/timetable/getStaffTimeTables',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    GetEmpTotalClassHours(inputData) {
        const options: any = {
            action: 'staff/timetable/getTotalClassHoursForEmpDirectory',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    } 
    
    GetPayslips(inputData) {
        const options: any = {
            action: 'hrmemp/payslip/getPayslipsForEmpDirectory',
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    } 
}